import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

const PartnerCard = ({ logoImg, name, url, description, category = null }) => (
  <div className="flex flex-col items-center justify-between px-2 py-3 bg-gray-300">
    <div className="flex flex-col items-center mb-4 lg:flex-row">
      <div
        className="w-4/5 mb-3 lg:w-1/3 lg:mb-0 lg:mr-4"
        style={{
          maxWidth: logoImg.width,
          maxHeight: logoImg.height,
        }}
      >
        <GatsbyImage image={logoImg} alt={name} />
      </div>
      <div className="lg:flex-1">
        {category && <p className="font-bold">Category: {category}</p>}
        <p>{description}</p>
      </div>
    </div>
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="block px-4 py-3 text-sm text-white bg-orange-700/70 sm:text-base hover:bg-orange-700/100 rounded-md"
    >
      Link to the webpage
    </a>
  </div>
);

PartnerCard.propTypes = {
  logoImg: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  category: PropTypes.string,
};

export default PartnerCard;
