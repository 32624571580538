import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import PartnerCard from "../../components/PartnerCard";
import HeroSection from "../../components/HeroSection";
import { Section } from "../../components/Section";
import { Seo } from "../../components/Seo";

// Head function
export const Head = ({ location }) => (
  <Seo pathname={location.pathname} />
);

// Program details
const ProgramDetails = () => (
  <>
    <div className="flex flex-col items-center md:items-stretch md:flex-row md:justify-center">
      <div className="flex flex-col justify-center text-center md:text-left md:mr-3 lg:mr-6">
        <p className="text-xl uppercase lg:text-2xl">What is</p>
        <p className="text-3xl font-bold uppercase md:mr-0 lg:text-4xl">
          Shine bright?
        </p>
      </div>
      <div className="hidden mr-3 border-l-2 border-black border-solid md:block lg:mr-6"></div>
      <div className="flex flex-col justify-center mt-4 text-center md:text-left lg:text-lg md:mt-0">
        <p className="w-64">
          Our friends are your friends. Together we unite our efforts so that
          you can succeed.
        </p>
      </div>
    </div>
    <div className="std-mt md:text-lg">
      <p className="font-bold">
        With the help of our friends, we help you shine brighter on the local
        job market!
      </p>
      <p className="my-4">
        Below you see the first-go-to organizations when you are in the
        beginning of your professional integration journey in Sweden. Don&apos;t
        hesitate to reach out directly to them.
      </p>
      <p>
        <span className="font-bold">P.S.</span> Make sure to greet them from
        Perform On Top and remind them that they are fantastic!
      </p>
    </div>
  </>
);

// NGO partners
const partnersData = {
  drivHuset: {
    category: "entrepreneurship & freelance",
    description:
      "Drivhuset will provide you with free high-quality consultations about project management, entrepreneurship, and ways to push your project forward.",
    url: "https://goteborg.drivhuset.se/",
  },
  coompanion: {
    category: "self-employment & freelance",
    description:
      "Coompanion will provide you with free high-quality consultations about entrepreneurship, business strategy and networking possibilities.",
    url: "https://coompanion.se/",
  },
  oppetHus: {
    category: "job-hunt & mentorship",
    description:
      "ÖppetHus will provide you with a mentor from your industry. Mentors may facilitate your job-hunt and provide you with a professional recommendation.",
    url: "https://www.oppethus.se/",
  },
  mittLiv: {
    category: "job-hunt & mentorship",
    description:
      "MittLiv connects you with a mentor from your industry. Mentors may facilitate your job-hunt and provide you with a professional recommendation.",
    url: "https://mittliv.com/se/",
  },
};

const NGOPartners = ({ partnersImages }) => {
  const partnersCards = Object.entries(
    partnersImages
  ).map(([name, fluidImg], idx) => (
    <PartnerCard
      key={`partner-${idx}`}
      logoImg={fluidImg}
      name={name}
      {...partnersData[name]}
    />
  ));
  return (
    <div className="grid grid-flow-row grid-cols-1 sm:grid-cols-2 gap-4 std-mt">
      {partnersCards}
    </div>
  );
};

NGOPartners.propTypes = {
  partnersImages: PropTypes.object.isRequired,
};

// Program page
const ProgramPage = ({ data }) => {
  const partnersImages = {
    drivHuset: data.partnerDrivHusetLogo.childImageSharp.gatsbyImageData,
    coompanion: data.partnerCoompanionLogo.childImageSharp.gatsbyImageData,
    oppetHus: data.partnerOppetHusLogo.childImageSharp.gatsbyImageData,
    mittLiv: data.partnerMittLivLogo.childImageSharp.gatsbyImageData,
  };

  return (
    <>
      <HeroSection
        bgImg={data.heroBgImg}
        bgImgAltTxt="Hero background two people discuss"
        title="Shine bright"
        subtitle="Mentorship, supervision, networking"
        shaderClassName="opacity-30"
      />
      <main>
        <Section>
          <ProgramDetails />
          <NGOPartners partnersImages={partnersImages} />
        </Section>
      </main>
    </>
  );
};

ProgramPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const shineBrightQuery = graphql`
  query ShineBrightQuery {
    heroBgImg: file(relativePath: { eq: "shine-bright-hero-img.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    partnerDrivHusetLogo: file(relativePath: { eq: "driv-huset-logo.png" }) {
      childImageSharp {
        gatsbyImageData(height: 80, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    partnerCoompanionLogo: file(relativePath: { eq: "coompanion-logo.png" }) {
      childImageSharp {
        gatsbyImageData(height: 80, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    partnerOppetHusLogo: file(relativePath: { eq: "oppet-hus-logo.png" }) {
      childImageSharp {
        gatsbyImageData(height: 80, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    partnerMittLivLogo: file(relativePath: { eq: "mitt-liv-logo.png" }) {
      childImageSharp {
        gatsbyImageData(height: 80, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
  }
`;

export default ProgramPage;
